<template>
  <div style="min-height: calc(100vh - 20px);">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh;" align-v="center">
            <b-col class="d-lg-flex align-items-center justify-content-center">
              <b-row class="justify-content-center">
                <b-col>
                  <h2 class="font-weight-bold mb-4">
                    {{ $t('forgotPassword.title') }}
                  </h2>
                  <h5 class="mb-3">{{ $t('forgotPassword.detail') }}</h5>
                  <b-form>
                    <div v-if="forgotSuccess">
                      <b-row align-h="center">
                        <b-img
                            class="mb-3"
                            width="100px"
                            src="~@/assets/images/icon/send-mail-success.png"
                        />
                      </b-row>
                      <b-row align-h="center">
                        <h4>Check your email</h4>
                      </b-row>
                      <b-row align-h="center">
                        <h5>{{ form.email }}</h5>
                      </b-row>
                    </div>
                    <b-form-group v-else>
                      <b-form-input
                          id="txt-username"
                          type="text"
                          :placeholder="$t('forgotPassword.email')"
                          class="mb-3"
                          v-model="$v.form.email.$model"
                          :state="validateState('email')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="txt-username">
                        {{ $t('common.requiredField') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-row>
                      <b-col sm="12" md="6" lg="6" class="mb-1">
                        <b-button
                            variant="outline"
                            pill
                            block
                            class="mt-4 mb-3"
                            @click="backToLogin"
                        >
                          {{ $t('forgotPassword.backToLogin') }}
                        </b-button>
                      </b-col>
                      <b-col sm="12" md="6" lg="6">
                        <b-button
                            variant="gradient"
                            :disabled="$v.form.$invalid || countdown !== 0 || forgotLoad"
                            pill
                            block
                            class="mt-4 mb-3"
                            v-if="forgotSuccess"
                            @click="forgotPassword"
                        >

                          <div v-if="countdown !== 0">
                            Resend email in {{countdown}} sec.
                          </div>
                          <div v-else>
                            {{$t('forgotPassword.resend')}}
                          </div>
                        </b-button>
                        <b-button
                            variant="gradient"
                            :disabled="$v.form.$invalid || countdown_error !== 0 || forgotLoad"
                            pill
                            block
                            class="mt-4 mb-3"
                            v-else
                            @click="forgotPassword"
                        >
                          <div v-if="countdown_error !== 0">
                            Resend email in {{countdown_error}} sec.
                          </div>
                          <div v-else>
                            {{$t('forgotPassword.recoverPassword')}}
                            <b-spinner small v-show="forgotLoad"/>
                          </div>

                        </b-button>
                      </b-col>
                    </b-row>
                    <label style="color: #ff3b3b;">{{ errLabel }}</label>
                  </b-form>
                  <div>
                    <footer-blank/>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import Language from '@/components/common/Language'
import profileApi from '@/repository/profileApi'
import FooterBlank from "@/layouts/full-layout/footer/FooterBlankLayout.vue";

export default {
  name: 'ForgotPassword',
  components: {FooterBlank, Language},
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: '',
      reset_url: window.location.origin + '/Change-Password'
    },
    forgotLoad: false,
    forgotSuccess: false,
    errLabel: '',
    countdown: 0,
    countdown_error: 0,
    timer: 0
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    backToLogin() {
      this.$router.push('Login').catch(() => {
      })
    },
    forgotPassword() {
      this.forgotLoad = true
      this.errLabel = ''
      profileApi
          .forgotPassword(this.form)
          .then((response) => {
            const resp = response?.data
            let rate = Number(response.headers['ratelimit-reset'])
            if (resp?.codeSYS === '001') {
              let timer = rate;
              this.forgotSuccess = true
              let interval = setInterval(() => {
                if (timer === 0) {
                  clearInterval(interval)
                  this.countdown = 0
                } else {
                  this.countdown = timer--
                }
              }, 1000)
            } else {
              let timer = rate;
              let interval = setInterval(() => {
                if (timer === 0) {
                  clearInterval(interval)
                  this.countdown = 0
                } else {
                  this.countdown = timer--
                }
              }, 1000)
              this.errLabel = resp?.message
            }
          }).catch((err) => {
            let rate = Number(err.response.headers['ratelimit-reset'])
            let timer = rate;
            let interval = setInterval(() => {
              if (timer === 0) {
                clearInterval(interval)
                this.countdown_error = 0
              } else {
                this.countdown_error = timer--
              }
            }, 1000)
            if (err.response.data.errors.code === "405") {
              this.errLabel = this.$t('forgotPassword.email_not_found')
            } else {
              this.errLabel = err.response.data.message
            }
          }).finally(() => {
            this.forgotLoad = false
          })
    },
  },
}
</script>
<style>
.logo-login {
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 4px 0px 4px 0px rgba(148, 149, 155, 0.55) !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #3e5569;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
